
.paypal-donate-button {
  background-color: #0070ba; /* PayPal-Blau */
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.paypal-logo {
  background-image: url('../../../public/de-pp-logo-150px.png'); /* F�gen Sie das PayPal-Logo hinzu */
  background-size: 50px 16px; /* Neue Gr��e des Logos (1/3 der urspr�nglichen Gr��e) */
  background-repeat: no-repeat;
  width: 50px; /* Neue Breite des Logos (1/3 der urspr�nglichen Breite) */
  height: 16px; /* Neue H�he des Logos (1/3 der urspr�nglichen H�he) */
  margin-right: 10px;
}