.store-name {
    text-decoration: none;
    color: inherit;
    display: block;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.store-item-image {
    height: 180px;
    object-fit: cover;
}

//.store-item:hover {
//    box-shadow: 0 0 10px;
//    transition: box-shadow 0.3s ease-in-out;
//}
