.sidebar-store-item {
  position: relative;
  cursor: pointer;

  .card-title {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 8px;
    border-radius: 5px;
    text-align: center;
    white-space: nowrap; /* Ensure text doesn't wrap to the next line */
    overflow: hidden; /* Hide any overflowing content */
    text-overflow: ellipsis;
    z-index: 1; /* Set a higher z-index to ensure the tooltip appears in front */
    font-weight: bold;
  }

  &:hover .card-title {
    display: block;
  }

  .sidebar-store-item-img {
    height: 47px;
    object-fit: cover;
  }
}
