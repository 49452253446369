@import '../../../custom.scss';

.rating-user-pp {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    object-fit: cover;
}

.rating-user-pp-small {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    object-fit: cover;
}

.comment-container {
    font-weight: bold;
    font-size: 1.125rem;
}

.upvote-count {
    margin-left: 15px !important;
    margin-bottom: 0;
    line-height: 1;
    display: flex;
    align-items: center;
}

.disabled-upvote-button {
    color: map-get($theme-colors, "grey") !important;
    pointer-events: none;
    border-color: map-get($theme-colors, "grey") !important;
}

.no-link-style {
    color: inherit;
    text-decoration: none;
}

.no-link-style:hover {
    color: inherit;
}

.user-name {
    font-weight: bold;
}

.shadow-box {
    padding: 0 !important;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2) !important;
}

.card-footer-white {
    background: map-get($theme-colors, "primary") !important;
}
