@import '../../custom.scss';

.ranking-container,
.top-ten-container {
  margin-bottom: 100px;
}

.store-rank {
  position: relative;
  padding: 2px;
  border: 2px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &.rank-1 {
    border-color: #e6be3c;
    background-color: #e6be3c;
    margin-bottom: 20px;
    .rank-label {
      background-color: #e6be3c;
    }
  }

  &.rank-2 {
    border-color: silver;
    background-color: silver;
    .rank-label {
      background-color: silver;
    }
  }

  &.rank-3 {
    border-color: #ed9137;
    background-color: #ed9137;
    .rank-label {
      background-color: #ed9137;
    }
  }

  .rank-label {
    position: absolute;
    top: -10px;
    left: -10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: map-get($theme-colors, "text") !important;
  }
}

.store-rank:hover {
  box-shadow: 0 0 10px;
  transition: box-shadow 0.3s ease-in-out;
}

.store-rank-top-10-desktop {
    position: relative;
    border: 2px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .rank-label-top-10 {
        position: absolute;
        top: -8px;
        left: -8px;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: map-get($theme-colors, "text") !important;;
        background-color: map-get($theme-colors, "grey") !important;
    }
}

.store-rank-top-10-desktop:hover {
  box-shadow: 0 0 10px;
  transition: box-shadow 0.3s ease-in-out;
}

@media (max-width: 576px) {
  .store-rank {
    position: relative;
    padding: 2px;
    border: 2px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;

    &.rank-1 {
      border-color: #e6be3c;
      .rank-label {
        background-color: #e6be3c;
      }
    }

    &.rank-2 {
      border-color: silver;
      width: 87.5%;
      .rank-label {
        background-color: silver;
      }
    }

    &.rank-3 {
      border-color: #ed9137;
      width: 75%;
      .rank-label {
        background-color: #ed9137;
      }
    }

    .rank-label {
      position: absolute;
      top: -10px;
      left: -10px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: map-get($theme-colors, "text") !important;
    }
  }

  .store-rank-top-10 {
    position: relative;
    padding: 10px;
    margin-bottom: 1rem;

    .rank-label-top-10 {
      position: absolute;
      top: -6px;
      left: -6px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: map-get($theme-colors, "text") !important;;
      background-color: map-get($theme-colors, "grey") !important;
    }
  }

  .top-ten-container {
    .MuiList-root {
      display: flex;
      flex-direction: column;
    }

    .MuiListItem-root {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .avatar-wrapper {
    position: relative;
    display: inline-block;
  }

  .avatar-wrapper .MuiAvatar-root {
    border: 2px solid #ddd;
  }
}

@media (min-width: 577px) {
  .ranking-container{
    margin-top: 12px;
  }
  .rank-1,
  .rank-2,
  .rank-3 {
    width: auto;
    float: none;
  }
}

.custom-padding-row > * {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
