@import '../../custom.scss';

.navbar-container {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    background-color: map-get($theme-colors, "secondary") !important;

    .search-container {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 10px;

        .search-field-container {
            position: relative;
            display: flex;
            align-items: center;
            width: 400px;
        }

        .search-input {
            height: 45px;
            width: 100%;
            padding: 0 10px;
            border-radius: 35px 0 0 35px;
            border: 1px solid map-get($theme-colors, "primary") !important;
            border-right: none;
            outline: none;
            flex: 1;
        }

        .search-type-dropdown {
            .dropdown-toggle {
                height: 45px;
                border-radius: 0 35px 35px 0;
                border: 1px solid map-get($theme-colors, "primary") !important;
                border-left: none;
                background-color: map-get($theme-colors, "primary") !important;
                color: map-get($theme-colors, "black_text") !important;
                padding: 0 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .dropdown-menu {
                border-radius: 10px;
                border: 1px solid map-get($theme-colors, "grey") !important;
                background-color: map-get($theme-colors, "primary") !important;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
            }

            .dropdown-item {
                &:hover {
                    background-color: map-get($theme-colors, "secondary") !important;
                }
            }
        }

        .search-results {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            max-height: 300px;
            overflow-y: auto;
            border: 1px solid map-get($theme-colors, "grey") !important;
            background-color: white;
            z-index: 1001;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
            border-radius: 30px;
            padding-top: 12px; /* Add padding for rounded corners */
            box-sizing: border-box;

            /* Scrollbar styling */
            &::-webkit-scrollbar {
                width: 6px; /* Adjust width of the scrollbar */
            }

            &::-webkit-scrollbar-track {
                margin-top: 25px; /* Add margin to prevent scrollbar track from reaching the top */
                margin-bottom: 40px; /* Add margin to prevent scrollbar track from reaching the bottom */
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: map-get($theme-colors, "secondary") !important; /* Thumb color */
                border-radius: 8px; /* Adjust the border-radius of the scrollbar thumb */
                background-clip: content-box; /* Ensure the border does not affect the thumb size */
            }

            &::-webkit-scrollbar-thumb:hover {
                background: map-get($theme-colors, "accent_light") !important; /* Thumb color on hover */
            }
        }

        .search-item {
            display: flex;
            align-items: center;
            padding: 10px;
            cursor: pointer;
            text-decoration: none;
            height: 60px;

            &:hover {
                background-color: map-get($theme-colors, "secondary") !important;
            }

            img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
                border-radius: 50%;
                object-fit: cover;
            }

            .item-info {
                display: flex;
                flex-direction: column;

                .item-name {
                    font-weight: bold;
                    color: map-get($theme-colors, "black_text") !important;
                    font-size: 0.9em;
                }

                .item-city {
                    font-size: 0.8em;
                    color: map-get($theme-colors, "grey") !important;
                }
            }
        }
    }

    .search-container-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-right: 10px;

        .search-input {
            height: 45px;
            width: 100%;
            padding: 0 10px;
            border-radius: 35px 0 0 35px;
            border: 1px solid map-get($theme-colors, "primary") !important;
            border-right: none;
            outline: none;
            flex: 1;
        }

        .search-type-dropdown-mobile {
            .dropdown-toggle {
                height: 45px;
                border-radius: 0 35px 35px 0;
                border: 1px solid map-get($theme-colors, "primary") !important;
                border-left: none;
                background-color: map-get($theme-colors, "primary") !important;
                color: map-get($theme-colors, "black_text") !important;
                padding: 0 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .dropdown-menu {
                border-radius: 10px;
                border: 1px solid map-get($theme-colors, "grey") !important;
                background-color: map-get($theme-colors, "primary") !important;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
                z-index: 1002 !important;
            }

            .dropdown-item {
                &:hover {
                    background-color: map-get($theme-colors, "secondary") !important;
                }
            }
        }

        .search-results-mobile {
            position: fixed;
            top: 60px;
            left: 2.5%;
            width: 95%;
            max-height: 300px;
            overflow-y: auto;
            border: 1px solid map-get($theme-colors, "grey") !important;
            background-color: white;
            z-index: 1001;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            padding-top: 12px; /* Add padding for rounded corners */

            /* Scrollbar styling */
            &::-webkit-scrollbar {
                width: 8px; /* Adjust width of the scrollbar */
            }

            &::-webkit-scrollbar-track {
                margin-top: 12px; /* Add margin to prevent scrollbar track from reaching the top */
                margin-bottom: 12px; /* Add margin to prevent scrollbar track from reaching the bottom */
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: map-get($theme-colors, "secondary") !important; /* Thumb color */
                border-radius: 8px; /* Adjust the border-radius of the scrollbar thumb */
                background-clip: content-box; /* Ensure the border does not affect the thumb size */
            }

            &::-webkit-scrollbar-thumb:hover {
                background: map-get($theme-colors, "accent_light") !important; /* Thumb color on hover */
            }
        }

        .search-item {
            display: flex;
            align-items: center;
            padding: 10px;
            cursor: pointer;
            text-decoration: none;
            height: 60px;

            &:hover {
                background-color: map-get($theme-colors, "secondary") !important;
            }

            img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
                border-radius: 50%;
                object-fit: cover;
            }

            .item-info {
                display: flex;
                flex-direction: column;

                .item-name {
                    font-weight: bold;
                    color: map-get($theme-colors, "black_text") !important;
                    font-size: 0.9em;
                }

                .item-city {
                    font-size: 0.8em;
                    color: map-get($theme-colors, "grey") !important;
                }
            }
        }
    }

    .logout-button {
        margin-left: 0.5rem;
        cursor: pointer;
    }

    .logout-button-mobile {
        margin-left: 0;
        cursor: pointer;
    }

    .nav-profile-picture {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-left: 5px;
        object-fit: cover;
    }

    .nav-profile-picture-mobile {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        object-fit: cover;
    }
}

.navbar-container {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-wrap: nowrap !important;
    align-items: center;
    justify-content: space-between;

    .search-container-mobile {
        flex-grow: 1;
        display: flex;
        align-items: center;
        min-width: 0;

        .search-input {
            flex: 1;
            min-width: 0;
        }

        .search-type-dropdown-mobile {
            flex-shrink: 0;
        }
    }
}

.navbar-container {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-wrap: nowrap !important;
    align-items: center;
    justify-content: space-between;

    .search-container-mobile {
        flex-grow: 1;
        display: flex;
        align-items: center;
        min-width: 0;

        .search-input {
            flex: 1;
            min-width: 0;
        }

        .search-type-dropdown-mobile {
            flex-shrink: 0;
        }
    }
}

.navbar-container {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-wrap: nowrap !important;
    align-items: center;
    justify-content: space-between;

    .search-container-mobile {
        flex-grow: 1;
        display: flex;
        align-items: center;
        min-width: 0;

        .search-input {
            flex: 1;
            min-width: 0;
        }

        .search-type-dropdown-mobile {
            flex-shrink: 0;
        }
    }
}

@media (max-width: 991px) {
    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: map-get($theme-colors, "secondary");
        z-index: 1000;
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Links ausrichten */
        padding: 10px 15px; /* Abstand zum Rand */
        border-bottom: 2px solid white; /* Wei�e Linie am unteren Rand */
    }

    .navbar-collapse .nav-link,
    .navbar-collapse .google-login-wrapper {
        width: 100%;
        text-align: left;
        margin-bottom: 5px;
        align-items: flex-start !important; /* Links ausrichten */
    }

    .navbar-collapse .logout-button-mobile {
        margin-left: 0;
    }

    .google-login-wrapper {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }
}


