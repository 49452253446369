@import '../../custom.scss';

.show-all-field {
  position: sticky;
  bottom: -1px;
  background-color: map-get($theme-colors, "secondary") !important;
  color: map-get($theme-colors, "text") !important;
  font-weight: bold;
  font-size: 0.9em;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
