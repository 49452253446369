@import '../../custom.scss';
//@import './FontSizes.scss';

.impressum-header {
  color: map-get($theme-colors, "primary") !important;
}

.impressum-container {
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  background-color: map-get($theme-colors, "primary") !important;
  padding: 20px;
  border: 1px solid map-get($theme-colors, "grey") !important;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 70vh;
  color: map-get($theme-colors, "black_text") !important;
}

.impressum-title {
  font-weight: bold;
  font-size: 12px;
  text-align: left;
}

.impressum-content {
  text-align: left;
  margin-top: 1rem;
  color: map-get($theme-colors, "black_text") !important;
}

.contact-mail {
  text-decoration: none;
  color: map-get($theme-colors, "black_text") !important;
}

.impressum-button-container {
  text-align: right;
}

.close-impressum-button {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid map-get($theme-colors, "secondary") !important;
  background-color: map-get($theme-colors, "secondary") !important;
  color: map-get($theme-colors, "black_text") !important;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s;
}

.close-impressum-button:hover {
  background-color: map-get($theme-colors, "grey") !important;
  color: map-get($theme-colors, "primary") !important;
  border: 2px solid map-get($theme-colors, "grey") !important;
}

p {
  color: map-get($theme-colors, "black_text") !important;
}

@media (max-width: 768px) {
  .impressum-container {
    width: 90vw;
    height: 70vh;
    overflow-y: scroll;
  }

  .impressum-button-container {
    text-align: center;
  }
}

.impressum-wrapper {
  .impressum-section {
    margin-bottom: 20px;

    h2 {
      font-size: 1.2em;
    }

    p {
      margin: 0;
    }
  }

  .representatives {
    font-size: 0.9em;
    color: #666;
  }

  .impressum-container {
    .impressum-title {
      font-size: 1.4em;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}
