@import '../../../custom.scss';

.filter-icon {
    font-size: 9pt;
}

input.custom-range::-webkit-slider-thumb {
    background-color: map-get($theme-colors, "secondary");
}

input.custom-range::-moz-range-thumb {
    background-color: map-get($theme-colors, "secondary");
}

input.custom-range::-ms-thumb {
    background-color: map-get($theme-colors, "secondary");
}

.range-label {
    display: flex;
    gap: 15px;
}

.rangle-label-rating {
    text-align: left !important;
}

.max-characters-reached {
    color: map-get($theme-colors, "red");;
}