.profile-picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  display: block;
}

.profile-picture-container {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  border-radius: 100%;
  margin: 0 auto;
}

.user-container {
    font-family: "Lato", sans-serif !important;
}

.user-info-container {
    margin-top: 2rem;
    line-height: 30px !important;
}

.highest-rating-container {
  display: flex;
  gap: 15px;
  margin-bottom: auto;
}

.change-username-input {
  height: 100%;
}

.gold-rating {
  font-size: 3rem;
  color: #e6be3c;
}

.silver-rating {
  font-size: 3rem;
  color: #C0C0C0;
}

.bronze-rating {
  font-size: 3rem;
  color: #ed9137;
}

.top-store-box {
  cursor: pointer;
}

.profile-picture-hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-picture-container .profile-picture-hover {
  display: block;
}

.profile-store-rank {
  position: relative;
  padding: 1px;
  border: 2px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &.profile-rank-1 {
    border-color: #e6be3c;
    background-color: #e6be3c;
    .profile-rank-label {
      background-color: #e6be3c;
    }
  }

  &.profile-rank-2 {
    border-color: silver;
    background-color: silver;
    .profile-rank-label {
      background-color: silver;
    }
  }

  &.profile-rank-3 {
    border-color: #ed9137;
    background-color: #ed9137;
    .profile-rank-label {
      background-color: #ed9137;
    }
  }

  .profile-rank-label {
    position: absolute;
    top: -10px;
    left: -10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
  }
}
.profile-store-rank:hover {
  box-shadow: 0 0 10px;
  transition: box-shadow 0.3s ease-in-out;
}

@media (max-width: 576px) {
  .profile-store-rank {
    position: relative;
    padding: 1px;
    border: 2px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;

    &.profile-rank-1 {
      border-color: #e6be3c;
      background-color: #e6be3c;
      .profile-rank-label {
        background-color: #e6be3c;
      }
    }

    &.profile-rank-2 {
      border-color: silver;
      background-color: silver;
      .profile-rank-label {
        background-color: silver;
      }
    }

    &.profile-rank-3 {
      border-color: #ed9137;
      background-color: #ed9137;
      .profile-rank-label {
        background-color: #ed9137;
      }
    }

    .profile-rank-label {
      position: absolute;
      top: -10px;
      left: -10px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: white;
    }
  }
}


@media (max-width: 767px) {
  .top-store-box {
    margin-bottom: 1rem;
  }

  .username {
    text-align: center;
    margin-top: 1.5rem;
    justify-content: center;
  }

}
