@import '../../custom.scss';

.store-overview-container {
    max-width: 60% !important;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.page-holder {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.body-wrapper {
    padding: 12px 16px 16px;
    max-width: 1200px;
    margin: auto;
    min-width: 62%;
}

.pagination-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    .page-number {
        margin: 0 1rem;
        font-size: 1.2rem;
    }

    button {
        margin: 0 0.5rem;
        background-color: map-get($theme-colors, "secondary") !important;
        color: map-get($theme-colors, "text") !important;
    }
}
