
$theme-colors: (
  "bread": #FFD770,
  "meat": #c2826d,
  "vegetable": #97CCCD,
  "sauce": #FFC2CE,
  "price-performance": #c78ec7,
  "primary": #F5F5F5,
  "secondary": #94C69C,
  "accent_light": #C7F9CC,
  "accent_dark": #80ED99,
  "black_text": #000000,
  "grey": #9299AC,
  "red": #FE7676,
  "text": #fff
);
// Importiere Bootstrap
@import '~bootstrap/scss/bootstrap';
/* src/index.css or src/App.css */
@import "~bootstrap-icons/font/bootstrap-icons.css";
