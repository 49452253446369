@import '../../custom.scss';

.footer-container {
  height: 140px;
  background-color: map-get($theme-colors, "secondary") !important;
  width: 100%;
  flex-shrink: 0;
  position: relative;
  margin-bottom: -140px;
}

.content-container {
  padding-top: 30px;
  color: map-get($theme-colors, "primary") !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.impressum-item {
  font-weight: bold;
  padding-top: 4px;
  cursor: pointer;
}
