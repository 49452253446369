.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.navbar-divider {
  margin-bottom: 100px;
  flex-shrink: 0;
}

main {
  flex-grow: 1;
}

h1 {
  font-family: "Lato", sans-serif !important;
  color: #333;
}
