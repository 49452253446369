.address-container {
    font-family: "Lato", sans-serif !important;
    line-height: 15px !important;
    overflow: hidden;
}

.random-comment-container {
    width: 100% !important;
    height: 100% !important;
}

.rating-container {
/*     display: flex;
    justify-content: center; */
    margin-left: 0;
}

.bar-container {
    width: 90% !important;
}

.store-image {
    border-radius: 10px !important;
    height: 180px;
    object-fit: cover;
}

p {
    color: #9299AC;
    font-size: 1.125rem;
}

.row-margin {
    margin-top: 30px !important;
}

.row {
    justify-content: center;
}

.page-holder {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.main {
    display: flex;
}

.sidebar {
    --animate-duration: .3s;
    top: 70px;
    text-align: left;
    padding: 0 16px 16px;
    border-left: 1px solid #f0f1f6;
    flex-basis: 30%;
    min-width: 300px;
    max-width: 355px;
}

.body-wrapper {
    padding: 12px 16px 16px;
    max-width: 1200px;
    margin: auto;
}

.image-holder {
    text-align: center;
}

.total-top-rating-container {
    text-align: left;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: baseline;
}

.store-headline-container {
    display: flex;
    justify-content: space-between;
}

.edit-store-icon {
    cursor: pointer;
    font-size: 1.25rem;
}

.edit-store-icon::after {
    display: none !important;
}


/* Media Query für Tablet und Mobilgeräte */
@media only screen and (max-width: 991px) {
    .sidebar {
        display: none; /* Sidebar ausblenden auf Tablet und Mobilgeräten */
    }
}
