.add-store-button {
    margin-left: 1rem;
}

.mobile-add-store-button {
    display: none;
}

.spinner-container {
    text-align: center;
}

@media (max-width: 991px) {
    .add-store-button {
        display: none !important;
    }

    .mobile-add-store-button {
        display: block;
    }
}
